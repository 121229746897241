<template>
  <div class="w-full max-w-lg relative mx-auto py-8 sm:py-16 px-4">
    <MagicLink />
  </div>
</template>

<script>
import MagicLink from '@components/Auth/MagicLink'

export default {
  components: {
    MagicLink
  }
}
</script>

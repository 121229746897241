<template>
  <div v-if="state === states.LOADING">
    <Loader />
  </div>
  <div v-else-if="state === states.MALFORMED_ERROR">
    <h1 class="text-2xl md:text-3xl xl:text-4xl">
      Sorry, something’s gone wrong
    </h1>
    <p>
      Try checking the link, or <router-link
        :to="{ name: 'sign-in' }"
        class="text-base cta-link font-medium"
      >
        request a new one
      </router-link>
    </p>
  </div>
  <div v-else-if="state === states.EXPIRED_LINK">
    <h1 class="text-xl md:text-3xl xl:text-4xl">
      Sorry, that link is too old to be used
    </h1>
    <p class="mt-2">
      <BaseButton :to="{ name: 'sign-in' }">
        Sign in again to request a new link
      </BaseButton>
    </p>
  </div>
</template>

<script>
import Loader from '@components/Loader'

const states = {
  LOADING: 'loading',
  MALFORMED_ERROR: 'malformed_error',
  EXPIRED_LINK: 'expired_link'
}

export default {
  components: {
    Loader
  },

  data() {
    return {
      states,
      isProcessing: true,
      expiredError: false,
      malformedError: false
    }
  },

  computed: {
    /**
     * @return {string}
     */
    state() {
      if (this.malformedError) {
        return states.MALFORMED_ERROR
      }
      if (this.expiredError) {
        return states.EXPIRED_LINK
      }

      return states.LOADING
    },

    /**
     * @return {string}
     */
    id() {
      return this.$route.query.id
    },

    /**
     * @return {string}
     */
    token() {
      return this.$route.query.token
    },

    /**
     * @return {string}
     */
    ghost() {
      return this.$route.query.ghost
    },

    /**
     * @return {Boolean}
     */
    canSignIn() {
      return this.id && this.token
    }
  },

  created() {
    this.signIn()
  },

  methods: {
    signIn() {
      if (!this.canSignIn) {
        this.malformedError = true
        return
      }

      this.$store.dispatch('employers/getTokenFromMagicLink', {
        id: this.id,
        token: this.token,
        ghost: !!this.ghost
      })
        .then(() => {
          this.$router.push({ name: 'client-assessments-list' })
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            this.expiredError = true
            throw error
          }
          this.malformedError = true
          throw error
        })
    }
  }
}
</script>
